// src/components/About.jsx
import React from 'react';

const About = () => {
  return (
<section id="about" class="about-section">
    <div class="container">
    <div class="about-me-heading">
    <h2>About Me  <span class="hand-wave">👋</span> </h2>

</div>

        <div class="about-card">
            <p>I develop robust and efficient software solutions using modern tech stack that includes AWS, TypeScript, Java, and Node.js. I thrive in dynamic environments that foster creativity and innovation and excel in collaborating with cross-functional teams to deliver high-quality results.To know more on my professional journey and my technical expertise, delve into the depths of my comprehensive resume.</p>
        </div>
    </div>
   
</section>

  );
};

export default About;
