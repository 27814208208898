import React from 'react';

const Contact = () => {
    return (
        <section id="contact" className="contact-section">
            <div className="contact-heading">
                <h2>Get in touch</h2>
                <p>
                    You can type in the details here, but unfortunately this won’t work. But don’t worry, if I build your form that will work.
                </p>
            </div>

            <div className="contact-form">
                <div className="contact-row">
                    <input type="text" placeholder="Your name" className="contact-input" />
                    <input type="email" placeholder="Your email" className="contact-input" />
                </div>
                <textarea placeholder="Message" className="contact-textarea"></textarea>
                <button type="submit" className="contact-submit">Submit</button>
            </div>
        </section>
    );
};

export default Contact;
