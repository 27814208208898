// pages/Home.jsx
import React from 'react';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import profilePhoto from '../profile_photo.jpg'
const Home = () => {
 
  return (
    <>
      <section class="bg-main bg-color hero-section">
        <div class="container">

          <img src={profilePhoto} alt="Your Name" className="profile-photo" />
          <h1 className='name'>Nitin Saxena</h1>
          <p className='intro-line'> With a strong passion for continuos learning <br />
            and skill innovation, I constantly explore new technologies to expand my knowledge.</p>

          <div className='social-links'>
            <OverlayTrigger placement="top" overlay={<Tooltip>Visit my X</Tooltip>}>
              <a href="https://x.com/oppa_2" target="_blank" rel="noopener noreferrer">
                <i class="bi bi-twitter-x"></i>
              </a>
            </OverlayTrigger>

            <OverlayTrigger placement="top" overlay={<Tooltip>Visit my GitHub</Tooltip>}>
              <a href="https://github.com/nitins19" target="_blank" rel="noopener noreferrer">
                <i class="bi bi-github"></i>
              </a>
            </OverlayTrigger>

            <OverlayTrigger placement="top" overlay={<Tooltip>Visit my LinkedIn</Tooltip>}>
              <a href="https://www.linkedin.com/in/nitin-saxena-82a702172/" target="_blank" rel="noopener noreferrer">
                <i class="bi bi-linkedin"></i>
              </a>
            </OverlayTrigger>

          </div>

          <a href="#" className="btn btn-primary">Work with me</a>

        </div>

      </section>

    </>
  );
};

export default Home;
