// src/components/Experience.jsx
import React from 'react';
import { FaCalendarAlt } from 'react-icons/fa';

const Experience = () => {
    const experiences = [
        {
            role: 'Contractor',
            company: 'Principal Global Services',
            duration: 'Mar 2023 - Present · 1 yr 9 mos',
            description: [
                'Deployed on-site as Contractor via LTIMindtree focusing on leveraging cloud technologies.',
                'Developing and maintaining key components of a life insurance platform, focusing on enhancing functionality across multiple service domains and each domain having its own business function.',
                'Actively contribute to team in a domain-driven design environment.',
                'Building reliable APIs and microservices using AWS to handle data exchanges smoothly, improving how different parts of the system work together and enhance overall performance.',
                'Involved in the development and maintenance of scalable and secure software solutions, utilizing the tech stack including AWS, TypeScript, Spring Boot, and Node.js.',
                'Design and implement robust solutions, backed by thorough unit testing.',
            ]
        },
        {
            role: 'Software Engineer',
            company: 'LTIMindtree · Full-time',
            duration: 'Oct 2022 - Present · 2 yrs 2 mos',
            description: [
                'Transitioned from Graduate Engineer Trainee to full-time Software Engineer.',
                'Engaged in professional development through company-sponsored certifications and training.',
            ],
            subRole: {
                role: 'Graduate Engineer Trainee',
                duration: 'Aug 2022 - Oct 2022',
                description: [
                    'Engaged in professional development through company training.',
                    'Learning tech stacks like SpringBoot, Angular, SQL.',
                    'Transitioned to full time employee after good performance in team project.'
                ]
            }
        }
    ];

    return (
        <section id="experience" className="experience-section">
            <h2>Experience <span class="hand-wave">👔</span></h2>
            <div className="experience-grid">
                {experiences.map((exp, index) => (
                    <div key={index} className="experience-card">
                        <div className="experience-header">
                            <FaCalendarAlt className="calendar-icon" />
                            <span className="experience-duration">{exp.duration}</span>
                        </div>
                        <h3 className="experience-title">
                            {exp.role} - <span className="company-name">{exp.company}</span>
                        </h3>
                        <ul className="experience-description">
                            {exp.description.map((desc, idx) => (
                                <li key={idx}>{desc}</li>
                            ))}
                        </ul>

                        {exp.subRole && (
                            <div className="sub-experience">
                                <div className="experience-header">
                                    <FaCalendarAlt className="calendar-icon" />
                                    <span className="experience-duration">{exp.subRole.duration}</span>
                                </div>
                                <h4 className="sub-role-title">
                                    {exp.subRole.role}
                                </h4>
                                <ul className="experience-description">
                                    {exp.subRole.description.map((desc, idx) => (
                                        <li key={idx}>{desc}</li>
                                    ))}
                                </ul>
                            </div>
                        )}
                    </div>
                ))}
            </div>
        </section>
    );
};

export default Experience;
