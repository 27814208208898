// components/Navbar.jsx
import React from 'react';
import '../App.css';

const NavBar = () => {
  return (
    <>
      <nav className="navbar navbar-expand-lg">
        <div className="container" >
          <a className="navbar-brand text-white" href="#">My Portfolio </a>
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon" ></span>
          </button>

          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <a className="nav-link text-capitalize " aria-current="page" href="#home">Home</a>
              </li>
              <li className="nav-item">
                <a className="nav-link text-capitalize " href="#about">About</a>
              </li>
              <li className="nav-item">
                <a className="nav-link text-capitalize " href="#skills">Skills</a>
              </li>
              <li className="nav-item">
                <a className="nav-link text-capitalize " href="#exp">Experience</a>
              </li>
              <li className="nav-item">
                <a className="nav-link text-capitalize " href="#contact">Contact</a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
};

export default NavBar;
