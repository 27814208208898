// src/components/Skills.jsx
import React from 'react';

const skills = [
    { category: 'Programming Languages', technologies: 'Java 8, TypeScript', level: '80' },
    { category: 'Cloud Platforms', technologies: 'AWS', level: '80' },
    { category: 'Version Control', technologies: 'Git, GitHub', level: '80' },
    { category: 'Frontend', technologies: 'React Js, Angular, JavaScript', level: '40' },
    { category: 'Frameworks and Libraries', technologies: 'Spring Boot, Spring Security, Hibernate', level: '60' },
    { category: 'Backend', technologies: 'Node.js, Express.js, TypeScript', level: '70' },
    { category: 'IDEs', technologies: 'VS Code, Eclipse, IntelliJ, STS', level: '90' },
    { category: 'Testing Frameworks', technologies: 'JUnit, Mocha', level: '70' }
];

const Skills = () => {
    return (
        <section id="skills" className="section-container">
            <h2 className="section-heading">Skills  <span class="hand-wave">🛠️</span></h2>
            <div className="skills-grid">
                {skills.map((skill, index) => (
                    <div key={index} className="skill-card">
                        <h3 className="skill-category">{skill.category}</h3>
                        <p className="skill-technologies">{skill.technologies}</p>
                        <div class="progress" role="progressbar" aria-label={`${skill.category} skill`} aria-valuenow={skill.level} aria-valuemin="0" aria-valuemax="100">
                            <div class="progress-bar progress-bar-striped bg-success progress-bar-animated" style={{ width: `${skill.level}%` }}>{skill.level}</div>
                        </div>
                    </div>
                ))}
            </div>
            
        </section>
    );
};

export default Skills;
