// src/components/About.jsx
import React from 'react';

const Footer = () => {
  return (
    <footer id="footer" className="footer">
    <p>2024 © Made by Nitin Saxena</p>
    </footer>


  );
};

export default Footer;
